:root {
    --black: #1a1a1a;
    --white: #ffffff;
    --accent: #580b0b;
    --cream: #F2E8CD;
    --grey: #F6F6F6;
    --text: rgb(107, 107, 107);
    --gap: 20px;
    --grey: #f6f6f6;
    --max-width: 1400px;
    --trans: all 0.4s ease-in-out;
    --shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    --rad: 10px;
    --semi-white: rgba(255, 255, 255, 0.76);
  }
  
  *::before,
  *::after,
  * {
    box-sizing: border-box;
    // border: 1px solid red;
    margin: 0px;
    font-family: "Poppins", sans-serif;
  }
  
  @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap");
  // font-family: 'Poppins', sans-serif;
  // font-family: "Teko", sans-serif;
  
  body {
    width: 100%;
    overflow-x: hidden;
    font-weight: 700;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  .heading,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Teko", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: var(--accent);
    line-height: 80%;
    text-transform: capitalize;
  }
  h2{
    font-size: 40px;
    
  }
  
  h3{
    font-size: 25px;
    
  }
  
  p {
    text-align: justify;
    font-size: 1rem;
    line-height: 28px;
    letter-spacing: 1px;
    word-spacing: 2px;
    font-weight: 300;
    color: var(--text);
  }
  
  .bg-img-cover {
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
  
  .bg-img-contain {
    background-position: center center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
  }
  
  .parent {
    width: 100vw;
    position: relative;
  
    .cont {
      max-width: var(--max-width);
      margin: auto;
      padding: 0px 10px;
      height: 100%;
      // background: rgba(102, 51, 153, 0.073);
  
  
     
    }
  }
  
  .btn {
    cursor: pointer;
    border: none;
    border: 3px solid var(--accent);
    background: transparent;
    color: var(--accent);
    font-size: 16px;
    padding: 5px 30px;
    border-radius: 200px;
    text-decoration: none;
    transition: var(--trans);
    font-weight: 600;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 5px;
    height: fit-content;
    position: relative;
  
  
    &:hover {
      color: var(--white) !important;
      background: var(--accent);
    }
  }
  
  a {
    text-decoration: none;
    cursor: pointer;
  }
  
  
  
  