.login-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-section {
  width: 500px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  .login-form {
    width: 100%;

    form {
      display: flex;
      flex-direction: column;
      gap: var(--gap);

      input {
        height: 40px;
        border-radius: var(--rad);
        padding: 10px 10px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
.login-section{
padding: 0px 10px !important;
}

}
