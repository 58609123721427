.dashboard-parent {
  height: 120px;

  width: 100% ;

  display: flex;
  justify-content: center;
  align-items: center;
padding-top: 20px;
position: sticky;
top: 0;
background-color: white;
box-shadow: var(--shadow);
// z-index: 100000;

  .dashboard-cont {
    padding: 0px 30px;
    width: 100%;
    display: flex;
    justify-content:space-between;
    align-items: center;
    
   
    gap: var(--gap);
   .entreis{
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--gap);
    .total-entreis {
      display: flex;
      justify-content: center;
      align-items: center;
    
    height: 50px;
    width: 250px;
    background-color: white;
    box-shadow: var(--shadow);
    border-radius: var(--rad);


    h2{
      color: rgba(54, 54, 54, 0.718);
      font-size: 50px;
      text-align: center;
    }
  }
   }

   .logout-btn{
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;


    button{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
    }
   }

   .search-bar{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc( var(--gap))/2;

    input{
      width: 100%;
      height: 50px;
      border-radius: var(--rad);
      font-size: 20px;
      padding: 0px 20px;
    }

    span{

      svg{
        font-size: 40px;
      }
    }
   }
  }
}
@media screen and (max-width:500px) {

  .dashboard-parent{
height: 250px !important;

  }
  .dashboard-cont{
    
    flex-direction: column !important;
    align-items: flex-start !important;

.entreis{
  width: 100% !important; 

  .total-entreis{
    width: 220px !important;
  }
}
    .search-bar{
      width: 100% !important;
    }

    .logout-btn{
      width: 100% !important;
    }
  }
}