.sidebar{
    width: 250px;
    height: 100vh;
    background-color:var(--accent) ;
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    align-items: center;
    padding: 30px 0px;

    h2{
     font-size: 50px;
     color: white;
    }

    .logout-btn{
        margin-top: 300px;

        .log-btn{
            border-color: var(--cream);
            color: white;
            cursor: pointer;
        
        
            &:hover{
                background-color: var(--cream);
                color: black !important;
            }
        }
        
    }

}
.links{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap);
   
    a{
        color: white;
        text-decoration: none;
    }

 
}